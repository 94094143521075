<template>
  <div class="page-events bg-light">
    <div id="events-feature" class="feature bg-feature">
      <h1 class="feature-lead py-5">EVENTS</h1>
    </div>
    <div
      class="
        container-lg
        mx-auto
        px-0
        pt-0 pt-md-5
        pb-5
        d-flex
        justify-content-between
        align-items-start
        position-relative
      "
    >
      <sidebar-left class="user-select-none" :class="{ expand: expandSidebar }">
        <h6
          class="
            mb-5
            text-muted
            d-flex
            justify-content-between
            align-items-center
          "
        >
          <span>
            <i class="fas fa-filter me-2" />
            Filter
          </span>
          <button
            class="
              d-md-none
              btn btn-sm btn-primary
              text-decoration-none
              fw-medium
              px-4
              rounded-pill
            "
            @click="expandSidebar = false"
          >
            Apply
          </button>
        </h6>
        <div>
          <p class="fw-medium mb-2">Pricing</p>
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              id="filterPricingAll"
              value="all"
              v-model="filters.pricing"
            />
            <label class="form-check-label" for="filterPricingAll">All</label>
          </div>
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              id="filterPricingFree"
              value="free"
              v-model="filters.pricing"
            />
            <label class="form-check-label" for="filterPricingFree">Free</label>
          </div>
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              id="filterPricingPaid"
              value="paid"
              v-model="filters.pricing"
            />
            <label class="form-check-label" for="filterPricingPaid">Paid</label>
          </div>
        </div>
        <div class="mt-4" v-if="eventTopics.length > 0">
          <p class="fw-medium mb-2">Event Topic</p>
          <div
            class="form-check"
            v-for="topic in eventTopics"
            :key="`filterTopic${topic}`"
          >
            <input
              class="form-check-input"
              type="checkbox"
              :id="`filterTopic${topic}`"
              :value="topic"
              v-model="filters.topic"
            />
            <label class="form-check-label" :for="`filterTopic${topic}`">
              {{ topic }}
            </label>
          </div>
        </div>
      </sidebar-left>
      <div
        class="
          row row-cols-2 row-cols-sm-3 row-cols-xl-4
          px-3
          g-2
          mx-0
          flex-fill
        "
      >
        <div class="sidebar-toggle col-12 d-md-none py-3 sticky-top bg-light">
          <button
            class="btn btn-primary w-100 py-3"
            @click="expandSidebar = true"
          >
            <i class="fas fa-filter me-2" />
            Filter
          </button>
        </div>
        <template v-if="!isLoading && events.length > 0">
          <div
            class="col"
            v-for="event in events.filter(
              (e) => this.Helper.getEventStatus(e) !== 'expired'
            )"
            :key="event._id"
          >
            <event-card :event="event" :is-booking="isBooking" />
          </div>
          <div
            class="col-12"
            v-if="
              events.filter((e) => this.Helper.getEventStatus(e) !== 'expired')
                .length < 1
            "
          >
            <div
              class="
                bg-white
                text-center text-muted
                fst-italic
                rounded
                border
                shadow-sm
                p-3
              "
            >
              No upcoming events
            </div>
          </div>
          <div
            v-if="
              events.filter((e) => this.Helper.getEventStatus(e) === 'expired')
                .length > 0
            "
            class="col-12 text-end py-3"
          >
            <button
              class="btn btn-sm btn-link text-decoration-none"
              @click="expandPastEvents = !expandPastEvents"
            >
              {{ expandPastEvents ? "Hide" : "Show" }} past events
            </button>
          </div>
          <template v-if="expandPastEvents">
            <div
              class="col"
              v-for="event in events.filter(
                (e) => this.Helper.getEventStatus(e) === 'expired'
              )"
              :key="event._id"
            >
              <event-card :event="event" :is-booking="isBooking" />
            </div>
          </template>
        </template>
        <div
          v-if="!isLoading && events.length < 1"
          class="
            col-12
            py-5
            text-center text-muted
            bg-white
            shadow-sm
            fst-italic
          "
        >
          Hmmm there is no event available now.<br />
          Check this space later for updates.
        </div>
        <loading-spinner
          class="position-relative bg-light text-muted"
          style="min-height: 250px"
          v-else-if="isLoading"
        >
          Loading
        </loading-spinner>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingSpinner from "@/components/LoadingSpinner";
import SidebarLeft from "@/components/SidebarLeft";
import EventCard from "@/components/EventCard";
import moment from "moment";
import Swal from "sweetalert2";

export default {
  name: "component-event-index",
  components: {
    LoadingSpinner,
    SidebarLeft,
    EventCard,
  },
  data() {
    return {
      events: [],
      isLoading: false,
      timerInterval: null,
      isBooking: null,
      expandSidebar: false,
      expandPastEvents: false,
      eventTopics: [],
      filters: {
        pricing: ["all"],
        topic: [],
      },
    };
  },
  watch: {
    expandSidebar: {
      handler() {
        if (this.expandSidebar) {
          document.body.classList.add("overflow-hidden", "overflow-hidden-md");
        } else {
          document.body.classList.remove(
            "overflow-hidden",
            "overflow-hidden-md"
          );

          this.$nextTick(() => {
            window.scrollTo(
              0,
              document.getElementById("events-feature").clientHeight -
                document.getElementById("main-navbar").clientHeight
            );
          });
        }
      },
      immediate: true,
    },
    filters: {
      handler() {
        if (this.filters.pricing.length > 1) {
          this.filters.pricing.shift();
        } else if (this.filters.pricing.length == 0) {
          this.filters.pricing = ["all"];
        }

        this.$nextTick(() => {
          this.getEvents();
        });
      },
      deep: true,
    },
  },
  methods: {
    async bookSession(event) {
      const user = this.$store.state.user;

      let eventIndex = this.events.findIndex((web) => web._id == event._id);

      if (user && eventIndex > -1) {
        if (event.price > 0) {
          Swal.fire({
            title: "<h5>You will be redirected to our payment form</h5>",
            html: "<p class='lh-sm small'>After you complete the payment, please allow up to 24 hours for our admin to update your booking in the system<p/>",
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonText: "Ok, let's proceed",
            icon: "info",
          }).then(async (result) => {
            if (result.isConfirmed) {
              window.open(event.onpay_link, "_blank");
            }
          });
        } else {
          Swal.fire({
            title: "<h5>Book a slot for this session?</h5>",
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonText: "Sure, get me a slot",
            icon: "question",
          }).then(async (result) => {
            if (result.isConfirmed) {
              this.isBooking = event._id;

              try {
                let { data } = await this.API.put(
                  `webinars/${event._id}/addParticipant`,
                  {
                    user: user._id,
                  }
                );

                data.action = this.getSessionAction(data);

                this.$set(this.events, eventIndex, data);

                Swal.fire(
                  "Booking successful",
                  "Your slot has been confirmes. Don't forget to tune in on time!",
                  "success"
                );
              } catch (error) {
                Swal.fire(
                  "Error",
                  "Something wrong happened while we are trying to book your slot. Please try adain later or contact our help desk.",
                  "error"
                );
              } finally {
                this.isBooking = null;
              }
            }
          });
        }
      } else {
        this.Helper.showLoginPrompt(this.$route.path);
      }
    },
    goToSession(event) {
      const user = this.$store.state.user;

      if (user) {
        this.$router.push({
          name: "EventDetails",
          params: { id: event._id },
        });
      } else {
        this.Helper.showLoginPrompt(`/events/${event._id}`);
      }
    },
    checkUserParticipation(event) {
      const user = this.$store.state.user;

      const eventParticipants = event.users
        ? event.users.map((participant) => participant._id)
        : [];

      if (user && eventParticipants.includes(user._id)) {
        return true;
      } else {
        return false;
      }
    },
    getSessionAction(event) {
      const eventStatus = this.Helper.getEventStatus(event);

      const userAlreadyBooked = this.checkUserParticipation(event);

      if (event.topic && event.topic.toLowerCase() == "competition") {
        // Action button untuk competition
        if (eventStatus != "expired") {
          // Belum jalan
          if (userAlreadyBooked) {
            return {
              is: "div",
              text: "BOOKED!",
              class: "alert alert-success small text-center py-2",
            };
          } else {
            if (event.onpay_link) {
              return {
                is: "button",
                text: "<i class='fas fa-money-check-alt' />&nbsp;Buy ticket",
                class: "btn btn-primary btn-sm w-100 py-2 text-nowrap",
                event: { click: () => this.bookSession(event) },
              };
            } else {
              return {
                is: "div",
                text: "COMING SOON",
                class: "py-2 small text-center text-muted fw-medium",
              };
            }
          }
        } else {
          // Dah habis
          return {
            is: "div",
            text: "Closed",
            class: "alert alert-danger small text-center py-2 text-nowrap",
          };
        }
      } else {
        // Action button untuk normal webinar
        if (eventStatus == "notYet") {
          // Belum jalan
          if (userAlreadyBooked) {
            return {
              is: "div",
              text: "BOOKED!",
              class: "alert alert-success small text-center py-2",
            };
          } else if (event.price > 0) {
            if (event.onpay_link) {
              return {
                is: "button",
                text: "<i class='fas fa-money-check-alt' />&nbsp;Buy ticket",
                class: "btn btn-primary btn-sm w-100 py-2 text-nowrap",
                event: { click: () => this.bookSession(event) },
              };
            } else {
              return {
                is: "div",
                text: "COMING SOON",
                class: "py-2 small text-center text-muted fw-medium",
              };
            }
          } else {
            return {
              is: "button",
              text: "Book a slot",
              class: "btn btn-dark btn-sm w-100 py-2 text-nowrap",
              event: { click: () => this.bookSession(event) },
            };
          }
        } else if (eventStatus == "ongoing") {
          // Tengah jalan
          if (userAlreadyBooked) {
            return {
              is: "button",
              text: "Join live!",
              class:
                "btn btn-secondary btn-sm text-light w-100 py-2 text-nowrap",
              event: { click: () => this.goToSession(event) },
            };
          } else if (
            this.$store.state.user &&
            this.$store.state.user.role.name == "Admin"
          ) {
            return {
              is: "button",
              text: "Join live!",
              class:
                "btn btn-secondary btn-sm text-light w-100 py-2 text-nowrap",
              event: { click: () => this.goToSession(event) },
            };
          } else if (event.price > 0) {
            if (event.onpay_link) {
              return {
                is: "button",
                text: "<i class='fas fa-money-check-alt' />&nbsp;Buy ticket",
                class: "btn btn-primary btn-sm w-100 py-2 text-nowrap",
                event: { click: () => this.bookSession(event) },
              };
            } else {
              return {
                is: "div",
                text: "COMING SOON",
                class: "py-2 small text-center text-muted fw-medium",
              };
            }
          } else {
            return {
              is: "button",
              text: "Join live!",
              class:
                "btn btn-secondary btn-sm text-light w-100 py-2 text-nowrap",
              event: { click: () => this.goToSession(event) },
            };
          }
        } else if (eventStatus == "expired") {
          // Dah habis
          return {
            is: "div",
            text: "Session ended",
            class: "alert alert-danger small text-center py-2 text-nowrap",
          };
        } else {
          // Entah
          return null;
        }
      }
    },
    async getEvents() {
      this.isLoading = true;

      clearInterval(this.timerInterval);

      let url = "webinars?_limit=-1&status_in=Published&_sort=start:ASC";

      let filterPricing = this.filters.pricing[0];
      let filterTopic = this.filters.topic;

      if (this.filters.pricing.length > 0 && filterPricing != "all") {
        url += `${filterPricing == "free" ? "&price=0.00" : "&price_gt=0.00"}`;
      }

      if (filterTopic.length > 0 && filterTopic.length < 3) {
        let topicQuery = filterTopic.map((ft) => `&topic_in=${ft}`);

        url += topicQuery.join("");
      }

      return this.API.get(url)
        .then((retVal) => {
          this.events = retVal.data.map((event) => {
            event.action = this.getSessionAction(event);

            return event;
          });

          retVal.data.forEach((data) => {
            let topic = data.topic ? data.topic.trim() : "";

            if (topic && !this.eventTopics.includes(topic)) {
              this.eventTopics.push(topic);

              this.eventTopics = this.eventTopics.sort();
            }
          });

          const expiredEvents = this.events
            .filter((event) => this.Helper.getEventStatus(event) == "expired")
            .sort((a, b) => {
              return new Date(b.start) - new Date(a.start);
            });

          this.events = this.events
            .filter(
              (event) => !expiredEvents.map((ew) => ew._id).includes(event._id)
            )
            .concat(expiredEvents);

          this.setEventTimer();

          this.timerInterval = setInterval(() => {
            this.setEventTimer();
          }, 60000); // every 1 minute
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    setEventTimer() {
      if (this.events.length > 0) {
        this.events.forEach((event) => {
          if (event.start) {
            event.timer = moment(event.start).fromNow();
          }
        });
      }
    },
  },
  mounted() {
    this.getEvents();
  },
  beforeDestroy() {
    clearInterval(this.timerInterval);
  },
};
</script>