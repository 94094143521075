<template>
  <div class="card rounded-card h-100">
    <loading-spinner
      class="position-absolute text-lightr rounded"
      v-if="isBooking == event._id"
    >
      Processing...
    </loading-spinner>
    <div
      class="card-tag badge py-2 px-3"
      :class="`bg-${event.price && event.price > 0 ? 'blue' : 'purple'}`"
    >
      {{ Helper.formatMoney(event.price, true) }}
    </div>
    <div class="card-image ratio ratio-16x9 bg-light shadow-bottom">
      <img
        :src="Helper.formatMediaUrl(event.poster)"
        class="card-img-top clickable cursor-zoom"
        :alt="event.title"
        @click="currentMedia = event.poster"
        v-if="event.poster"
      />
      <img
        src="@/assets/images/poster-default.jpg"
        class="card-img-top"
        :alt="event.title"
        v-else
      />
    </div>
    <div class="card-body pt-4">
      <p class="text-muted mb-2">{{ event.topic || "Webinar" }}</p>
      <p class="fw-medium card-title mb-0 lh-sm">
        {{ event.title }}
      </p>
    </div>
    <div class="card-footer bg-white border-0 p-0">
      <p class="mb-4 px-3 pt-3">
        {{ Helper.formatDate(event.start, "ddd DD MMM YYYY") }}
        <span
          v-if="event.timer"
          class="d-block small fw-medium text-capitalize"
        >
          {{ event.timer }}
        </span>
        <span class="d-block small">
          {{ Helper.formatDate(event.start, "hh:mm A") }}
          <template v-if="event.end">
            &middot; {{ getDuration(event.start, event.end) }}
          </template>
        </span>
      </p>
      <div v-if="event.fb_link || event.ig_link">
        <p class="mb-0 small text-center">View more info on</p>
        <div
          class="
            d-flex
            justify-content-center
            align-items-center
            social-media-icons
          "
        >
          <a
            v-if="event.fb_link"
            class="btn btn-link"
            :href="event.fb_link"
            target="_blank"
          >
            <img class="icon" src="@/assets/icons/facebook.png" />
          </a>
          <a
            v-if="event.ig_link"
            class="btn btn-link"
            :href="event.ig_link"
            target="_blank"
          >
            <img class="icon" src="@/assets/icons/instagram.png" />
          </a>
        </div>
      </div>
      <div class="invisible" v-else>
        <p class="mb-0 small text-center">No info</p>
        <div class="py-2 text-center">
          <img
            class="icon w-auto"
            style="height: 2rem"
            src="@/assets/images/poster-default.jpg"
          />
        </div>
      </div>
      <component
        v-if="event.action"
        :is="event.action.is"
        :class="event.action.class"
        class="mt-3 mb-0"
        v-bind="event.action.bind"
        v-on="event.action.event"
        v-html="event.action.text"
      ></component>
    </div>
    <media-viewer
      v-if="currentMedia"
      :url="this.Helper.formatMediaUrl(currentMedia)"
      @close="currentMedia = null"
    />
  </div>
</template>

<script>
import MediaViewer from "@/components/Modals/MediaViewer";
import LoadingSpinner from "@/components/LoadingSpinner";
import moment from "moment";

export default {
  props: ["event", "is-booking"],
  components: { MediaViewer, LoadingSpinner },
  data() {
    return {
      currentMedia: null,
    };
  },
  methods: {
    getDuration(start, end) {
      const diff = moment(end).diff(moment(start), "hours", true);

      if (diff >= 24.0) {
        const diffDay = moment(end).diff(moment(start), "days", true);

        if (diff == 24) {
          return `${diffDay} day`;
        } else {
          const decimals = diffDay.toString().split(".");

          if (decimals.length > 1) {
            const diffHour = Math.round(
              moment()
                .add(parseFloat("0.".concat(decimals[1])) * 24, "hours")
                .diff(moment(), "hours", true)
            );

            return `${parseInt(diffDay)} days ${diffHour} hours`;
          } else {
            return `${diffDay} days`;
          }
        }
      } else {
        return `${diff} ${diff > 1.0 ? "hours" : "hour"}`;
      }
    },
  },
};
</script>